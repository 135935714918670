input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@media (min-width: 2000px) {

  html,
  body {
    font-size: 24px;
  }
}

@media screen and (min-width: 1200px) and (max-height: 800px) {

  html,
  body {
    font-size: 12px;
  }
}